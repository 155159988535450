@use '~@internetstiftelsen/styleguide/src/globals';
@use '~@internetstiftelsen/styleguide/src/configurations/variables';
@use '~@internetstiftelsen/styleguide/src/configurations/extends';
@use '~@internetstiftelsen/styleguide/src/configurations/typography/typography';
@use '~@internetstiftelsen/styleguide/src/configurations/grid/grid';

@use '~@internetstiftelsen/styleguide/src/atoms/logotype/logotype';
@use '~@internetstiftelsen/styleguide/src/atoms/main-menu/main-menu';
@use '~@internetstiftelsen/styleguide/src/atoms/button/button';
@use '~@internetstiftelsen/styleguide/src/atoms/select/select';
@use '~@internetstiftelsen/styleguide/src/atoms/spinner/spinner';
@use '~@internetstiftelsen/styleguide/src/atoms/tag/tag';
@use '~@internetstiftelsen/styleguide/src/atoms/paging/paging';
@use '~@internetstiftelsen/styleguide/src/atoms/input/input';
@use '~@internetstiftelsen/styleguide/src/atoms/checkbox/checkbox';
@use '~@internetstiftelsen/styleguide/src/atoms/label/label';
@use '~@internetstiftelsen/styleguide/src/atoms/meta/meta';
@use '~@internetstiftelsen/styleguide/src/atoms/skip/skip';
@use '~@internetstiftelsen/styleguide/src/atoms/password-toggle/password-toggle';

@use '~@internetstiftelsen/styleguide/src/molecules/modal/modal';
@use '~@internetstiftelsen/styleguide/src/molecules/info-box/info-box';
@use '~@internetstiftelsen/styleguide/src/molecules/form-control/form-control';
@use '~@internetstiftelsen/styleguide/src/molecules/alert/alert';
@use '~@internetstiftelsen/styleguide/src/molecules/card/card';
@use '~@internetstiftelsen/styleguide/src/molecules/system-error/system-error';
@use '~@internetstiftelsen/styleguide/src/molecules/cookie-disclaimer/cookie-disclaimer';

@use '~@internetstiftelsen/styleguide/src/organisms/accordion/accordion';
@use '~@internetstiftelsen/styleguide/src/organisms/header/header';
@use '~@internetstiftelsen/styleguide/src/organisms/mailchimp/mailchimp';
@use '~@internetstiftelsen/styleguide/src/organisms/hero/hero';
@use '~@internetstiftelsen/styleguide/src/organisms/filter/filter';
@use '~@internetstiftelsen/styleguide/src/organisms/footer/footer';

@use '~@internetstiftelsen/styleguide/src/structures/article';

$modalZIndex: 2000;

html, body, p {
  font-size: variables.$size-base;
}

body table th {
  font-size: variables.$size-medium-plus;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Remove green outline on Chrome when clicking buttons */
button:focus, select:focus, input:focus {
  outline: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.highlight {
  border-top: .22222rem solid #ffce2e;
}

.card-pay-wrapper > div {
  width: 100%;

  iframe {
    width: 100%;
  }
}

/* BELOW FOLLOWS STYLEGUIDE CLASSES + MUI */
.MuiFormControl-root.a-input {
  padding: 0;
  border: 0;
}

/* BELOW FOLLOWS STYLEGUIDE CLASSES */

ul.skip {
  z-index: 1101;
}

#cookies {
  @import '~@internetstiftelsen/styleguide/src/molecules/table/table';
}

.site {
  main.site__main {
    width: 100%;
  }
}

.a-main-menu__list__link.MuiButton-root {
  &:hover {
    text-decoration: underline;
  }
}

.m-modal {
  z-index: $modalZIndex;
}

.m-modal__container {
  overflow-y: auto;
}

.m-card--padded .m-card__content .a-input p {
  margin-top: 0;
}

.a-paging:disabled {
  background-color: #ededed;
}

input[type="password"] + div > button.input-icon svg {
  fill: #8e9297;
}

input[type="text"] + div > button.input-icon svg {
    fill: #1f2a36;
}

.global-footer {
  margin-top: 3rem;
}

.hidden {
  display: none;
}
